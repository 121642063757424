import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LeftArrow from "../components/LeftArrow";
import RightArrow from "../components/RightArrow";
import Box from "../components/Box";
import { Link } from "react-router-dom";
export default function Brand() {
  let { id } = useParams();
  const [brandData, setBrandData] = useState();
  const [brandListLength, setBrandListLength] = useState();
  const [brandIndex, setBrandIndex] = useState();
  const [prevBrand, setPrevBrand] = useState();
  const [nextBrand, setNextBrand] = useState();
  function isCurrentBrand(brand) {
    return brand.id === parseInt(id);
  }
  useEffect(() => {
    const brandList = JSON.parse(window.localStorage.getItem("brandList"));
    setBrandListLength(brandList.length);
    const theBrand = brandList.find(({ title }) => title.rendered === id);

    const theBrandIndex = brandList.findIndex(
      ({ title }) => title.rendered === id
    );
    setBrandIndex(theBrandIndex);
    theBrandIndex > 0
      ? setPrevBrand(brandList[theBrandIndex - 1])
      : setPrevBrand(null);

    theBrandIndex < brandList.length - 1
      ? setNextBrand(brandList[theBrandIndex + 1])
      : setNextBrand(null);
    // }
    setBrandData(theBrand);
  }, [id]);
  return (
    <main>
      {brandData && (
        <div className="brand-container">
          <div
            className={
              brandData.acf.isdistributor === "true"
                ? "brand-left brand-left-distributor"
                : "brand-left"
            }
          >
            <div className="logo">
              <img src={brandData.acf.brand_page_logo.url} alt="" />
            </div>
            <div className="brand-info">
              <div className="brand-information-container">
                <div
                  className="brand-information"
                  dangerouslySetInnerHTML={{
                    __html: brandData.content.rendered,
                  }}
                ></div>
                {brandData && brandData.acf.isdistributor === "true" ? (
                  <div className="flex flex-right">
                    <a
                      href={`mailto:info@corbyframing.ca?subject=Dealer Inquery for ${brandData.title.rendered}`}
                      className="button"
                    >
                      Dealer Information
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex flex-right">
                  <a
                    href={`mailto:info@corbyframing.ca?subject=${brandData.title.rendered}`}
                    className="contact-button"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                  <a
                    href={brandData.acf.company_link}
                    className="button"
                    target="_blank"
                  >
                    Manufacturer's Website
                  </a>
                </div>
              </div>

              {brandData.acf.isdistributor === "true" ? (
                <div className="heading-section">
                  <div className="section-heading">Featured Product</div>
                </div>
              ) : (
                ""
              )}

              {brandData.acf.isdistributor === "true" &&
                brandData.acf.products.map((product, index) => {
                  return (
                    <div
                      className="brand-product-container"
                      key={"product" + index}
                    >
                      <img src={product.product_image.url} alt="" />
                      <div className="product-info">
                        <div className="section-subheading">
                          {product.product_name}
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: product.product_description,
                          }}
                        ></p>
                      </div>
                      <div></div>
                      <div className="flex flex-right">
                        <div className="contact-button">
                          <a
                            href={`mailto:info@corbyframing.ca?subject=${product.product_name}`}
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="brand-right">
            <img src={brandData.acf.brand_hero_image.url} alt="" />
          </div>
          <div className="arrow-nav">
            <div className="left-arrow-nav">
              {brandIndex > 0 && (
                <div className="arrow-container">
                  <Link to={`/Brand/${prevBrand.title.rendered}`}>
                    {/* <LeftArrow></LeftArrow> */}

                    <span>&#8592; {prevBrand.title.rendered}</span>
                    {/* <Box></Box> */}
                  </Link>
                </div>
              )}
            </div>
            <div className="right-arrow-nav">
              {brandIndex < brandListLength - 1 && (
                <div className="arrow-container">
                  <Link to={`/Brand/${nextBrand.title.rendered}`}>
                    <span>{nextBrand.title.rendered} &#8594;</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
