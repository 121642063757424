import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import ItemForSale from "../components/ItemForSale";
import classNames from "classnames";
import icoGridOn from "../images/icons/circled-menu.png";
import icoGridOff from "../images/icons/circled-menu-ff.png";
import icoListOn from "../images/icons/menu.png";
import icoListOff from "../images/icons/menu-ff.png";
import mat from "../images/mat/corbymat_206.png";
import ShopSiteSpecial from "../components/ShopSiteSpecial";
import TurntableMat from "../components/TurntableMat";

export default function Shop() {
  let sliderTimeout;

  const [activeSiteSpecial, setActiveSiteSpecial] = useState(0);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [soldItems, setSoldItems] = useState([]);
  const [soldPage, setSoldPage] = useState(1);
  const [soldPageCount, setSoldPageCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredSoldItems, setFilteredSoldItems] = useState([]);
  const [includeSold, setIncludeSold] = useState(false);
  const [includeSoldQuery, setIncludeSoldQuery] = useState("&sold=false");
  const [display, setDisplay] = useState("grid");
  const [siteSpecials, setSiteSpecials] = useState();
  const changeImage = (index) => {
    clearTimeout(sliderTimeout);
    setActiveSiteSpecial(index);
  };
  const shuffle = (itemsToShuffle) => {
    let currentIndex = itemsToShuffle.length;
    let tempVal;
    let randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      tempVal = itemsToShuffle[currentIndex];
      itemsToShuffle[currentIndex] = itemsToShuffle[randomIndex];
      itemsToShuffle[randomIndex] = tempVal;
    }
    return itemsToShuffle;
  };
  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = Math.round(windowHeight + window.scrollY);

    if (isLoading || docHeight > windowBottom + 100) {
      return;
    }
    if (page !== pageCount) {
      setPage(page + 1);
    }

    if (soldPage !== soldPageCount && includeSold) {
      setSoldPage(soldPage + 1);
    }
  };
  const handleChange = (e) => {
    setIncludeSold(e.target.checked);
  };
  function fetchSiteSpecials() {
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/site_special?acf_format=standard&expired=false`
      )
      .then((res) => {
        setSiteSpecials(res.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }
  function fetchSold() {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/for_sale_item?acf_format=standard&sold=true&per_page=10&page=${soldPage}`
      )
      .then((res) => {
        setSoldPageCount(parseInt(res.headers["x-wp-totalpages"]));
        setSoldItems([...soldItems, ...res.data]);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function fetchForSale() {
    setIsLoading(true);
    setError(null);
    setPageCount(null);
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/for_sale_item?acf_format=standard&sold=false&per_page=15&page=${page}`
      )
      .then((res) => {
        setPageCount(parseInt(res.headers["x-wp-totalpages"]));
        const itemsRandomized = shuffle(res.data);
        setItems([...items, ...itemsRandomized]);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    sliderTimeout = setInterval(() => {
      if (siteSpecials) {
        if (activeSiteSpecial + 1 < siteSpecials.length) {
          setActiveSiteSpecial(activeSiteSpecial + 1);
        } else {
          setActiveSiteSpecial(0);
        }
      }
    }, 15000);

    return () => {
      clearInterval(sliderTimeout);
    };
  }, [activeSiteSpecial, siteSpecials]);
  useEffect(() => {
    if (items) {
      let filteredList = items;
      if (categoryFilter !== "all") {
        filteredList = items.filter((item) => {
          return item.acf.product_category === categoryFilter;
        });
      }

      let sortedFilteredList = filteredList.sort((a, b) => {
        return a.acf.sold === b.acf.sold ? 0 : a.acf.sold === "true" ? 1 : -1;
      });
      setFilteredItems(sortedFilteredList);
    }
    if (soldItems) {
      let filteredList = soldItems;
      if (categoryFilter !== "all") {
        filteredList = soldItems.filter((item) => {
          return item.acf.product_category === categoryFilter;
        });
      }

      let sortedFilteredList = filteredList.sort((a, b) => {
        return a.acf.sold === b.acf.sold ? 0 : a.acf.sold === "true" ? 1 : -1;
      });
      setFilteredSoldItems(sortedFilteredList);
    }
  }, [items, soldItems, categoryFilter]);
  useEffect(() => {
    if (includeSold) {
      fetchSold();
    } else {
      setSoldItems([]);
    }
  }, [includeSold]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading]);
  useEffect(() => {
    setPageCount(null);
    fetchForSale();
  }, [page]);

  useEffect(() => {
    if (includeSold) {
      fetchSold();
    }
  }, [soldPage]);
  useEffect(() => {
    fetchSiteSpecials();
  }, []);
  return (
    <main>
      <div className="shop-container  page-container">
        <div className="heading-section">
          <div className="section-heading panel-button">
            Corby's Audio TurnTable Mat
          </div>
        </div>
        <div className="turntable-mat-container">
          <TurntableMat></TurntableMat>
        </div>
        <div className="heading-section">
          <div className="section-heading panel-button">SiteSpecials</div>
        </div>
        <div className="site-specials-container">
          {siteSpecials
            ? siteSpecials.map((item, index) => {
                return (
                  <ShopSiteSpecial
                    item={item}
                    active={activeSiteSpecial === index}
                    key={"siteSpecial" + index}
                  ></ShopSiteSpecial>
                );
              })
            : "loading..."}
          <div className="slider-circles">
            {siteSpecials &&
              siteSpecials.map((image, index) => {
                return (
                  <div
                    className={classNames(
                      "site-slider-circle",
                      index === activeSiteSpecial
                        ? "site-slider-circle-active"
                        : ""
                    )}
                    key={"circle" + index}
                    onClick={() => changeImage(index)}
                  ></div>
                );
              })}
          </div>
        </div>
        <div className="heading-section">
          <div className="section-heading">Used & Demo Items for sale</div>
        </div>
        <div className="category-filters">
          <div
            role="button"
            tabIndex="0"
            className={classNames(
              " section-subheading category-filter",
              categoryFilter === "all" ? "category-filter-active" : ""
            )}
            onClick={() => setCategoryFilter("all")}
          >
            All
          </div>
          <div
            role="button"
            tabIndex="0"
            className={classNames(
              " section-subheading category-filter",
              categoryFilter === "electronics" ? "category-filter-active" : ""
            )}
            onClick={() => setCategoryFilter("electronics")}
          >
            Electronics
          </div>
          <div
            role="button"
            tabIndex="0"
            className={classNames(
              " section-subheading category-filter",
              categoryFilter === "analog" ? "category-filter-active" : ""
            )}
            onClick={() => setCategoryFilter("analog")}
          >
            Analog
          </div>
          <div
            role="button"
            tabIndex="0"
            className={classNames(
              " section-subheading category-filter",
              categoryFilter === "loudspeakers" ? "category-filter-active" : ""
            )}
            onClick={() => setCategoryFilter("loudspeakers")}
          >
            Loudspeakers
          </div>
          <div
            role="button"
            tabIndex="0"
            className={classNames(
              " section-subheading category-filter",
              categoryFilter === "accessories" ? "category-filter-active" : ""
            )}
            onClick={() => setCategoryFilter("accessories")}
          >
            Accessories
          </div>
          <div className="search-sale-items">
            {/* <form onSubmit={handleSubmit}> */}
            <input
              type="checkbox"
              id="include-sold"
              value={includeSold}
              onChange={handleChange}
            />
            <label htmlFor="include-sold">Include Sold</label>

            {/* </form> */}
          </div>
          <div className="display-grid-list">
            {display === "grid" ? (
              <>
                <img src={icoGridOn} alt="" />
                <img
                  src={icoListOff}
                  alt=""
                  onClick={() => setDisplay("list")}
                />
              </>
            ) : (
              <>
                <img
                  src={icoGridOff}
                  alt=""
                  onClick={() => setDisplay("grid")}
                />
                <img src={icoListOn} alt="" />
              </>
            )}
          </div>
        </div>
        <div
          className={
            display === "grid"
              ? "sale-items-container-grid"
              : "sale-items-container-list"
          }
        >
          {filteredItems &&
            filteredItems.map((item, index) => {
              return (
                <ItemForSale
                  itemImage={item.acf.main_image}
                  itemTitle={item.title.rendered}
                  itemText={item.content.rendered}
                  itemGallery={item.acf.item_images}
                  itemDate={item.date}
                  itemPrice={"$" + Number(item.acf.price).toLocaleString("en")}
                  itemDisplay={display}
                  isHero={false}
                  key={"item" + index}
                ></ItemForSale>
              );
            })}
          {includeSold
            ? filteredSoldItems
              ? filteredSoldItems.map((item, index) => {
                  return (
                    <ItemForSale
                      itemImage={item.acf.main_image}
                      itemTitle={item.title.rendered}
                      itemText={item.content.rendered}
                      itemGallery={item.acf.item_images}
                      itemDate={item.date}
                      itemPrice={"SOLD"}
                      itemDisplay={display}
                      isHero={false}
                      key={"item" + index}
                    ></ItemForSale>
                  );
                })
              : ""
            : ""}
        </div>
      </div>
    </main>
  );
}
