import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import NewsModal from "./NewsModal";
import DOMPurify from "dompurify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,0,0,0.5)",
  },
};
const neutralizeBack = (callback) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
    callback();
  };
};
const revivalBack = () => {
  window.onpopstate = undefined;
  window.history.back();
};

export default function NewsItem(props) {
  ReactModal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalOpenTrue = () => {
    setModalIsOpen(true);
    neutralizeBack(setModalIsOpenToFalse);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
    revivalBack();
  };
  return (
    <>
      {props.isHero ? (
        <div className="latestNewsItem">
          <div className="heading-section">
            <div className="section-heading">Latest News</div>
          </div>

          <div className="item">
            <div className="item-image">
              <img src={props.itemHeroImage} />
            </div>
            <div className="item-details">
              <div className="item-heading">
                <div
                  className="item-title"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.itemTitle),
                  }}
                ></div>
                <div className="item-date">{props.itemDate}</div>
              </div>
              <div
                className="item-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.itemText),
                }}
              ></div>
            </div>
          </div>

          <div className="flex flex-right">
            <Link
              to="/News"
              className="button"
              state={{ newsId: props.itemId }}
            >
              Read More...
            </Link>
          </div>
        </div>
      ) : props.itemIndex < 5 ? (
        <div
          className={
            props.itemIndex === 0
              ? "news-item-main first-news-item"
              : "news-item news-item-" + props.itemIndex
          }
          onClick={setModalOpenTrue}
        >
          <img src={props.itemImage} alt="" />

          <div
            className="item-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.itemTitle),
            }}
          ></div>
        </div>
      ) : (
        <div
          className={
            props.itemIndex === 0
              ? "news-item-main first-news-item"
              : "news-item news-item-" + props.itemIndex
          }
          onClick={setModalOpenTrue}
        >
          <img src={props.itemImage} alt="" />
          {props.itemIndex === 0 ? (
            <>
              <div
                className="item-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.itemText),
                }}
              ></div>
            </>
          ) : (
            <>
              <div
                className="item-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.itemTitle),
                }}
              ></div>
            </>
          )}
        </div>
      )}
      <ReactModal
        isOpen={modalIsOpen}
        className={"modal news-modal"}
        closeTimeoutMS={750}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
        onRequestClose={setModalIsOpenToFalse}
      >
        <div
          onClick={setModalIsOpenToFalse}
          style={customStyles}
          className="modalClose"
        >
          close
        </div>
        <NewsModal itemProps={props}></NewsModal>
      </ReactModal>
    </>
  );
}
