import React from "react";
import classNames from "classnames";
export default function HeroSliderImage(props) {
  return (
    <div
      className={classNames(
        "heroSlider-image",
        props.imageActive ? "heroSlider-image-active" : ""
      )}
    >
      <img src={props.imageURL} alt={props.imageTitle} />
      {/* <div
        className={classNames(
          "hero-image-caption",
          props.imageActive ? "hero-image-caption-active" : ""
        )}
      >
        {props.imageTitle}
      </div> */}
    </div>
  );
}
