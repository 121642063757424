import React from "react";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Brand from "./pages/Brand";
import { Routes, Route, useLocation } from "react-router-dom";
import Shop from "./pages/Shop";
import News from "./pages/News";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import About from "./pages/About";

export const Context = React.createContext();

function App() {
  const location = useLocation();
  const setTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 450);
  };
  return (
    <>
      <Header></Header>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={1300}
          onEnter={setTop}
        >
          <Routes location={location}>
            <Route path="/" Component={Home}></Route>
            <Route path="/Brand/:id" Component={Brand}></Route>
            <Route path="/About" Component={About}></Route>
            <Route path="/Shop/" Component={Shop}></Route>
            {/* <Route path="/Shop/:id" Component={}></Route> */}
            <Route path="/News" Component={News}></Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </>
  );
}

export default App;
