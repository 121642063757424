import React, { useState } from "react";

export default function NewsModal(props) {
  const itemprops = props.itemProps;
  return (
    <>
      <div className="news-modal-item-container">
        <div className="heading-section">
          <div
            className="modal-title section-heading"
            dangerouslySetInnerHTML={{ __html: itemprops.itemTitle }}
          ></div>
        </div>
        <div className="modal-title section-subheading">
          {itemprops.itemDate}
        </div>
        <div
          className="news-modal-item-text"
          dangerouslySetInnerHTML={{ __html: itemprops.itemContent }}
        ></div>
      </div>
    </>
  );
}
