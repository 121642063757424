import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import HeroSliderImage from "./HeroSliderImage";
import classNames from "classnames";

export default function HeroSlider() {
  const [heroImages, setHeroImages] = useState(null);
  const [activeImage, setActiveImage] = useState(0);
  const [noTransition, setNoTransition] = useState(false);

  let sliderTimeout;

  const fetchHeroSection = () => {
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/corby_hero_image?acf_format=standard`
      )
      .then((res) => {
        setHeroImages(res.data[0].acf.hero_images);
      });
  };
  const changeImage = (index) => {
    clearTimeout(sliderTimeout);
    setActiveImage(index);
  };
  useEffect(() => {
    sliderTimeout = setInterval(() => {
      if (heroImages) {
        if (activeImage + 1 < heroImages.length) {
          setActiveImage(activeImage + 1);
        } else {
          setActiveImage(0);
        }
      }
    }, 10000);

    //Clearing the interval
    return () => {
      clearInterval(sliderTimeout);
    };
  }, [activeImage]);

  useEffect(() => {
    fetchHeroSection();
  }, []);
  return (
    <div className="heroSlider">
      <div className="heroSlider-track">
        <div className="heroSlider-images">
          {/* <div
          className={classNames(
            "heroSlider-images",
            noTransition ? "noTransition" : ""
          )}
          style={{ left: activeImage * -100 + "%" }}
        > */}
          {
            heroImages &&
              heroImages.map((image, index) => {
                return (
                  <HeroSliderImage
                    imageURL={image.url}
                    imageTitle={image.caption}
                    key={"img" + index}
                    imageActive={activeImage === index}
                  ></HeroSliderImage>
                );
              })
            // <img src="" alt="" />
          }
        </div>
      </div>
      <div className="welcome-text text-400">
        <span>Welcome to </span>
        <div className="logo">Corby's Audio</div>
        <span>Ontario’s home of the finest Audio Equipment.</span>
      </div>
      <div className="slider-circles">
        {heroImages &&
          heroImages.map((image, index) => {
            return (
              <div
                className={classNames(
                  "slider-circle",
                  index === activeImage ? "slider-circle-active" : ""
                )}
                key={"circle" + index}
                onClick={() => changeImage(index)}
              ></div>
            );
          })}
      </div>
    </div>
  );
}
