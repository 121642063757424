import React, { useState } from "react";
import fb from "../images/icons/facebook.svg";
import insta from "../images/icons/insta.svg";
import { Link, NavLink } from "react-router-dom";
import BrandNav from "./BrandNav";

export default function Footer() {
  const [brandNavOpen, setBrandNavOpen] = useState(false);
  const closeBrandNav = () => {
    setBrandNavOpen(false);
  };
  return (
    <div className="footer">
      <div className="footer-nav-menu" role="menubar">
        <div className="nav-item">
          <div onClick={() => setBrandNavOpen(!brandNavOpen)}>Brands</div>
          <BrandNav isOpen={brandNavOpen} setClosed={closeBrandNav}></BrandNav>
        </div>
        <div className="nav-item">
          <NavLink
            role="menuitem"
            to="/News"
            className="nav-link"
            onClick={() => setBrandNavOpen(false)}
          >
            News
          </NavLink>
        </div>
        <div className="nav-item">
          {" "}
          <NavLink
            role="menuitem"
            to="/About"
            className="nav-link"
            onClick={() => setBrandNavOpen(false)}
          >
            About
          </NavLink>
        </div>
        <div className="nav-item">
          <NavLink role="menuitem" to="/Shop/" className="nav-link">
            Shop
          </NavLink>
        </div>
        <div className="nav-item">
          <a
            href="https://www.facebook.com/corbysaudio"
            target="_blank"
            rel="noreferrer"
            className="nav-link"
            role="menuitem"
            aria-label="Link opens facebook.com"
          >
            <img src={fb} alt="" />
          </a>
        </div>
        <div className="nav-item">
          <a
            href="https://www.instagram.com/corbysaudio/"
            target="_blank"
            rel="noreferrer"
            className="nav-link"
            role="menuitem"
            aria-label="link opens instagram.com"
          >
            <img src={insta} alt="" />
          </a>
        </div>
      </div>
      <span className="copyright">
        Copyright &#169; 2024 Corby's Audio - All Rights Reserved.
      </span>
    </div>
  );
}
